import { FC } from 'react';
import { Helmet } from 'react-helmet-async';
import {
  Box,
  Container,
  Typography,
} from '@mui/material';
import useSession from 'src/hooks/useSession';
import { useLocation } from 'react-router-dom';
import RegisterBlock from 'src/components/authentication/register/RegisterBlock';
import { BookingSuccessDetails } from 'src/components/booking';
import useAuth from 'src/hooks/useAuth';
import { BookingSuccessState } from 'src/types/booking/bookingSuccessState';
import useSettings from 'src/hooks/useSettings';
import TipsAndUpdatesIcon from '@mui/icons-material/TipsAndUpdates';
import { Stack } from '@mui/system';
import { isCustomDomain } from 'src/hooks/useCustomDomain';

const BookingSuccess: FC = () => {
  const { session } = useSession();
  const location = useLocation();
  const { settings } = useSettings();
  const { isAuthenticated } = useAuth();
  const bookingSuccessState = location.state ? (location.state as BookingSuccessState) : null;

  if (!bookingSuccessState) return null;

  const getPageTitle = () => {
    if (isCustomDomain()) {
      return 'Buchung abgeschlossen';
    }
    return 'Kumao | Buchung abgeschlossen';
  };

  return (
    <>
      <Helmet>
        <title>{getPageTitle()}</title>
      </Helmet>
      <Container
        maxWidth={settings.compact ? 'lg' : false}
        sx={{ pt: 4 }}
      >
        <BookingSuccessDetails
          colorCode="#000000"
          organization={bookingSuccessState.organization}
          selectedServices={bookingSuccessState.selectedServices}
          selectedEvent={bookingSuccessState.selectedEvent}
          icsEntry={bookingSuccessState.icsEntry}
        />
        {(!isAuthenticated) && !isCustomDomain() && (
          <Box sx={{ mt: 2 }}>
            <Stack
              direction="row"
              sx={{ pb: 5, pt: 5 }}
            >
              <TipsAndUpdatesIcon fontSize="large" />
              <Typography sx={{ pl: 2, fontFamily: 'Poppins', fontSize: 20, fontWeight: 700, textAlign: 'center' }}>
                Wenn der Buchungsprozess noch einfacher sein soll, dann registriere dich doch einfach jetzt bei Kumao
              </Typography>
            </Stack>
            <RegisterBlock
              firstName={session?.userData?.firstName}
              lastName={session?.userData?.lastName}
              email={session?.userData?.userEmail}
              isBookingSuccessRegister
              disableGutters
            />
          </Box>
        )}
      </Container>
    </>
  );
};

export default BookingSuccess;
