import { FC } from 'react';
import {
  Box,
  SxProps, Theme
} from '@mui/material';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import YouTubeIcon from '@mui/icons-material/YouTube';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { EnSocialMediaType } from 'src/api/dto/socialmedia/enSocialMediaType';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import PinterestIcon from '@mui/icons-material/Pinterest';
import XIcon from '@mui/icons-material/X';
import LinkIcon from '@mui/icons-material/Link';

export interface SocialMediaIconsProps {
  icon: EnSocialMediaType;
  sx?: SxProps<Theme>;
}

const SocialMediaIcons: FC<SocialMediaIconsProps> = (props) => {
  const { icon, sx } = props;

  return (
    <Box sx={sx}>
      {icon === EnSocialMediaType.Facebook && (
      <FacebookIcon color="primary" />
      )}
      {icon === EnSocialMediaType.Instagram && (
      <InstagramIcon color="primary" />
      )}
      {icon === EnSocialMediaType.Youtube && (
      <YouTubeIcon color="primary" />
      )}
      {icon === EnSocialMediaType.LinkedIn && (
      <LinkedInIcon color="primary" />
      )}
      {icon === EnSocialMediaType.Phone && (
      <PhoneIcon color="primary" />
      )}
      {icon === EnSocialMediaType.Email && (
      <EmailIcon color="primary" />
      )}
      {icon === EnSocialMediaType.Pinterest && (
      <PinterestIcon color="primary" />
      )}
      {icon === EnSocialMediaType.Twitter && (
      <XIcon color="primary" />
      )}
      {(icon === EnSocialMediaType.Snapchat || icon === EnSocialMediaType.TikTok || icon === EnSocialMediaType.Website || icon === EnSocialMediaType.Discord) && (
      <LinkIcon color="primary" />
      )}
    </Box>
  );
};

export default SocialMediaIcons;
