import { serverConfig } from 'src/config';

export const isCustomDomain = () => {
  const { hostname } = window.location;
  return serverConfig.hostname !== hostname;
};

export const customDomain = () => {
  const { hostname } = window.location;
  return hostname;
};
