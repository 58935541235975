export enum EnSocialMediaType {
  Instagram = 'INSTAGRAM',
  Facebook = 'FACEBOOK',
  Youtube = 'YOUTUBE',
  Discord = 'DISCORD',
  Email = 'EMAIL',
  Phone = 'PHONE',
  Website = 'WEBSITE',
  TikTok = 'TIKTOK',
  Pinterest = 'PINTEREST',
  Snapchat = 'SNAPCHAT',
  Twitter = 'TWITTER',
  LinkedIn = 'LINKEDIN'
}

// DISCORD, EMAIL, FACEBOOK, INSTAGRAM, PHONE, PINTEREST, SNAPCHAT, TIKTOK, TWITTER, WEBSITE, YOUTUBE
