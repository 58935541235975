import { isCustomDomain } from 'src/hooks/useCustomDomain';
import makeStringUrlFriendly from './makeStringUrlFriendly';

const generateUrlFriendly = (text: string, id: number, path: string = '') => {
  let url = '/';

  if (isCustomDomain()) {
    if (path !== '') {
      url = `${path}`;
    }
  } else {
    url = `/${makeStringUrlFriendly(`${text}-${id}`)}${path}`;
  }

  return url;
};

export default generateUrlFriendly;
