import {
  FC,
  useState,
  useCallback,
  useEffect
} from 'react';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Box,
  Button,
  Checkbox,
  FormHelperText,
  Switch,
  TextField,
  Typography,
  Link,
  IconButton,
  Stack
} from '@mui/material';
import { UserRegisterDTO } from 'src/api/dto/user/userRegister';
import { BrancheListDTO } from 'src/api/dto/organization/brancheList';
import axios from 'src/lib/axios';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import Country from 'flagit';
import phoneRegExp from 'src/utils/regexExp';
import countries from 'src/utils/countries';
import UserMessageSnackbar from 'src/components/widgets/userMessage/UserMessageSnackbar';
import { LicenseId } from 'src/api/dto/organization/licenseId';
import InfoIcon from '@mui/icons-material/Info';
import { useNavigate } from 'react-router-dom';
import PricePackageLabels from 'src/utils/pricePackageLabels';
import useAuth from '../../../hooks/useAuth';
import useIsMountedRef from '../../../hooks/useIsMountedRef';

const userRegisterDTO: UserRegisterDTO = {
  businessUser: false,
  firstName: '',
  lastName: '',
  email: '',
  password: '',
  phone: '',
  newsletter: false,
  companyName: '',
  vatNum: '',
  branches: [],
  streetName: '',
  streetNumber: '',
  zipCode: '',
  city: '',
  country: 'AT',
  useOpenAIForContent: true,
  brancheNameDetailed: ''
};

export interface SimpleProps {
  toggleBusinessSwitchState?: (state: boolean) => void;
  firstname?: string;
  lastname?: string;
  email?: string;
  pricePackage?: string;
  hideBusinessRegistration?: boolean;
}

const pricePackages: LicenseId[] = [
  'FREE',
  'BASIS',
];

const RegisterJWT: FC<SimpleProps> = (props) => {
  const { toggleBusinessSwitchState, firstname, lastname, email, pricePackage, hideBusinessRegistration } = props;

  const isMountedRef = useIsMountedRef();
  const { register } = useAuth();
  const navigate = useNavigate();
  const [listBranchen, setListBranchen] = useState<BrancheListDTO[] | null>(null);
  const [showErrorText, setShowErrorText] = useState(false);
  const [errorText, setErrorText] = useState('');

  const getListBranchen = useCallback(async () => {
    try {
      const response = await axios.get<BrancheListDTO[]>('/api/account/getBranches');

      if (isMountedRef.current) {
        setListBranchen(response.data);
      }
    } catch (err) {
      console.error(err);
    }
  }, [isMountedRef]);

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setShowErrorText(false);
  };

  useEffect(() => {
    getListBranchen();
  }, [getListBranchen]);

  if (!listBranchen) {
    return null;
  }
  return (
    <Formik
      initialValues={{
        businessUser: pricePackage ? true : userRegisterDTO.businessUser,
        email: email ?? userRegisterDTO.email,
        firstname: firstname ?? userRegisterDTO.firstName,
        lastname: lastname ?? userRegisterDTO.lastName,
        password: userRegisterDTO.password,
        phone: userRegisterDTO.phone,
        newsletter: userRegisterDTO.newsletter,
        companyname: userRegisterDTO.companyName,
        vatNum: userRegisterDTO.vatNum,
        brancheList: '',
        streetName: userRegisterDTO.streetName,
        streetNumber: userRegisterDTO.streetNumber,
        zipCode: userRegisterDTO.zipCode,
        city: userRegisterDTO.city,
        country: userRegisterDTO.country,
        policy: false,
        submit: null,
        pricePackage: !pricePackage ? pricePackages[0] : pricePackage,
        useOpenAIForContent: userRegisterDTO.useOpenAIForContent,
        brancheNameDetailed: userRegisterDTO.brancheNameDetailed,
      }}
      validationSchema={Yup.object().shape({
        businessUser: Yup.bool(),
        email: Yup.string().email('Bitte gib eine gültige E-Mail Adresse an!').max(255).required('*erforderlich'),
        firstname: Yup.string().max(255).required('*erforderlich'),
        lastname: Yup.string().max(255).required('*erforderlich'),
        password: Yup.string().min(8, 'Passwort muss mindestens 8 Zeichen haben!').max(40).required('Passwort ist erforderlich')
          .matches(/^[^\\]+$/, 'Backslash nicht erlaubt'),
        companyname: Yup.string().when('businessUser', {
          is: true,
          then: Yup.string().max(255).required('*erforderlich')
        }),
        phone: Yup.string().when('businessUser', {
          is: true,
          then: Yup.string().matches(phoneRegExp, 'Ungültige Telefonnummer!').required('*erforderlich'),
          otherwise: Yup.string().matches(phoneRegExp, 'Ungültige Telefonnummer!').notRequired()
        }),
        vatNum: Yup.string().when('businessUser', {
          is: true,
          then: Yup.string().max(255)
        }),
        brancheList: Yup.string().when('businessUser', {
          is: true,
          then: Yup.string().notOneOf(['-1']).required('Bitte auswählen')
        }),
        pricePackage: Yup.string().when('businessUser', {
          is: true,
          then: Yup.string().notOneOf(['-1']).required('Bitte auswählen')
        }),
        streetName: Yup.string().when('businessUser', {
          is: true,
          then: Yup.string().max(255).required('*erforderlich')
        }),
        streetNumber: Yup.string().when('businessUser', {
          is: true,
          then: Yup.string().max(255).required('*erforderlich')
        }),
        zipCode: Yup.number().when('businessUser', {
          is: true,
          then: Yup.number().typeError('Postleitzahl ungültig!').min(1000, 'Postleitzahl ungültig!').max(99999, 'Postleitzahl ungültig')
            .required('*erforderlich')
        }),
        city: Yup.string().when('businessUser', {
          is: true,
          then: Yup.string().max(255).required('*erforderlich')
        }),
        country: Yup.string().when('businessUser', {
          is: true,
          then: Yup.string().max(255).required('*erforderlich')
        }),
        policy: Yup.boolean().oneOf([true], 'Bitte bestätige unsere Geschäftsbedingung!')
      })}
      onSubmit={async (values, {
        setStatus,
        setSubmitting,
        resetForm
      }) => {
        try {
          setShowErrorText(false);
          userRegisterDTO.businessUser = values.businessUser;
          userRegisterDTO.email = values.email;
          userRegisterDTO.firstName = values.firstname;
          userRegisterDTO.lastName = values.lastname;
          userRegisterDTO.newsletter = values.newsletter;
          userRegisterDTO.password = values.password;
          userRegisterDTO.companyName = values.companyname;
          userRegisterDTO.vatNum = values.vatNum;
          userRegisterDTO.branches = [listBranchen.find((element) => element.id === Number(values.brancheList))];
          userRegisterDTO.streetName = values.streetName;
          userRegisterDTO.streetNumber = values.streetNumber;
          userRegisterDTO.zipCode = values.zipCode;
          userRegisterDTO.city = values.city;
          userRegisterDTO.country = values.country;
          userRegisterDTO.phone = values.phone;
          userRegisterDTO.useOpenAIForContent = values.useOpenAIForContent;
          userRegisterDTO.brancheNameDetailed = values.brancheNameDetailed;

          if (values.businessUser) {
            userRegisterDTO.licenseId = values.pricePackage as LicenseId;
          }

          await register(userRegisterDTO);

          if (isMountedRef.current) {
            setStatus({ success: true });
            setSubmitting(false);
            resetForm();

            // navigate will be overruled by guest guard
            navigate('/register-successful');
          }
        } catch (err) {
          console.error(err);
          setStatus({ success: false });
          setShowErrorText(true);
          setErrorText(err.message);
          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
      }): JSX.Element => (
        <form
          noValidate
          onSubmit={handleSubmit}
        >
          <Grid
            container
            spacing={1}
          >

            <Grid
              item
              xs={12}
              sm={6}
            >
              <TextField
                error={Boolean(touched.firstname && errors.firstname)}
                fullWidth
                helperText={touched.firstname && errors.firstname}
                label="Vorname"
                required
                margin="normal"
                name="firstname"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.firstname}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
            >

              <TextField
                error={Boolean(touched.lastname && errors.lastname)}
                fullWidth
                helperText={touched.lastname && errors.lastname}
                label="Nachname"
                required
                margin="normal"
                name="lastname"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.lastname}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              xs={12}
            >
              <TextField
                error={Boolean(touched.email && errors.email)}
                fullWidth
                required
                helperText={touched.email && errors.email}
                label="E-Mail"
                margin="normal"
                name="email"
                onBlur={handleBlur}
                onChange={handleChange}
                type="email"
                value={values.email}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              xs={12}
            >
              <TextField
                error={Boolean(touched.password && errors.password)}
                fullWidth
                required
                helperText={touched.password && errors.password}
                label="Passwort"
                margin="normal"
                name="password"
                onBlur={handleBlur}
                onChange={handleChange}
                type="password"
                value={values.password}
                variant="outlined"
              />
            </Grid>
            {!hideBusinessRegistration && (
            <Grid
              item
              xs={12}
            >
              <Box
                alignItems="center"
                display="flex"
                mt={1}
                ml={-1}
              >
                <Typography
                  color="textSecondary"
                  variant="body2"
                  fontWeight={700}
                >
                  Du bist Dienstleister?
                </Typography>
              </Box>
              <Box
                alignItems="center"
                display="flex"
                mt={1}
                ml={-1}
              >
                <Switch
                  checked={values.businessUser}
                  onChange={(e) => { handleChange(e); if (toggleBusinessSwitchState) toggleBusinessSwitchState(e.target.checked); }}
                  name="businessUser"
                  color="primary"
                />
                <Typography
                  variant="body2"
                  color="textPrimary"
                >
                  Ja, ich möchte
                  {' '}
                  <b>mein Unternehmen</b>
                  {' '}
                  auf Kumao präsentieren!
                </Typography>
              </Box>
            </Grid>
            )}
          </Grid>
          {/* ### Optional Business User Registration ### */}
          {values.businessUser && (

            <Grid
              container
              spacing={1}
            >
              <Grid
                item
                xs={12}
              >
                <TextField
                  error={Boolean(touched.companyname && errors.companyname)}
                  fullWidth
                  required
                  helperText={touched.companyname && errors.companyname}
                  label="Firmenbezeichnung"
                  margin="normal"
                  name="companyname"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.companyname}
                  variant="outlined"
                />
              </Grid>

              <Grid
                item
                xs={12}
              >
                <TextField
                  error={Boolean(touched.brancheList && errors.brancheList)}
                  helperText={touched.brancheList && errors.brancheList}
                  fullWidth
                  margin="normal"
                  label="Kategorie*"
                  name="brancheList"
                  onChange={handleChange}
                  select
                  SelectProps={{ native: true }}
                  value={values.brancheList}
                  variant="outlined"
                >
                  {values.brancheList === '' && (
                    <option
                      key={-1}
                      value={-1}
                    >
                      &nbsp;
                    </option>
                  )}
                  {listBranchen.map((brancheList) => (
                    <option
                      key={brancheList.id}
                      value={brancheList.id}
                    >
                      {brancheList.name}
                    </option>
                  ))}
                </TextField>
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
              >
                <TextField
                  error={Boolean(touched.brancheNameDetailed && errors.brancheNameDetailed)}
                  fullWidth
                  helperText={touched.brancheNameDetailed && errors.brancheNameDetailed}
                  label="Branche"
                  margin="normal"
                  name="brancheNameDetailed"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.brancheNameDetailed}
                  variant="outlined"
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
              >
                <Stack direction="row">
                  <TextField
                    error={Boolean(touched.pricePackage && errors.pricePackage)}
                    helperText={touched.pricePackage && errors.pricePackage}
                    fullWidth
                    margin="normal"
                    label="Lizenzpaket*"
                    name="pricePackage"
                    onChange={handleChange}
                    select
                    SelectProps={{ native: true }}
                    value={values.pricePackage}
                    variant="outlined"
                  >
                    {values.pricePackage === '' && (
                    <option
                      key={-1}
                      value={-1}
                    >
                      &nbsp;
                    </option>
                    )}
                    {pricePackages.map((item) => (
                      <option
                        key={item}
                        value={item}
                      >
                        {PricePackageLabels.get(item)}
                      </option>
                    ))}
                  </TextField>
                  <IconButton
                    aria-label="information"
                    color="primary"
                    sx={{ mt: 1 }}
                    href="/pricing"
                    target="_blank"
                  >
                    <Stack
                      direction="column"
                      sx={{ alignItems: 'center',
                        justifyContent: 'center' }}
                    >
                      <InfoIcon />
                      <Typography variant="caption">
                        Preispakete
                      </Typography>
                    </Stack>
                  </IconButton>
                </Stack>
              </Grid>

              <Grid
                item
                xs={12}
                sm={6}
              >
                <TextField
                  error={Boolean(touched.vatNum && errors.vatNum)}
                  fullWidth
                  helperText={touched.vatNum && errors.vatNum}
                  label="USt.-Id"
                  margin="normal"
                  name="vatNum"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.vatNum}
                  variant="outlined"
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
              >
                <TextField
                  error={Boolean(touched.phone && errors.phone)}
                  fullWidth
                  required
                  helperText={touched.phone && errors.phone}
                  label="Telefonnummer"
                  margin="normal"
                  name="phone"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.phone}
                  variant="outlined"
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={8}
              >
                <TextField
                  error={Boolean(touched.streetName && errors.streetName)}
                  fullWidth
                  required
                  helperText={touched.streetName && errors.streetName}
                  label="Strasse"
                  margin="normal"
                  name="streetName"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.streetName}
                  variant="outlined"
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={4}
              >
                <TextField
                  error={Boolean(touched.streetNumber && errors.streetNumber)}
                  fullWidth
                  required
                  helperText={touched.streetNumber && errors.streetNumber}
                  label="Hausnummer"
                  margin="normal"
                  name="streetNumber"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.streetNumber}
                  variant="outlined"
                />
              </Grid>

              <Grid
                item
                xs={12}
                sm={4}
              >
                <TextField
                  error={Boolean(touched.zipCode && errors.zipCode)}
                  fullWidth
                  required
                  helperText={touched.zipCode && errors.zipCode}
                  label="Postleitzahl"
                  margin="normal"
                  name="zipCode"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.zipCode}
                  variant="outlined"
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={8}
              >

                <TextField
                  error={Boolean(touched.city && errors.city)}
                  fullWidth
                  required
                  helperText={touched.city && errors.city}
                  label="Ort"
                  margin="normal"
                  name="city"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.city}
                  variant="outlined"
                />
              </Grid>

              <Grid
                item
                xs={12}
              >
                <TextField
                  error={Boolean(touched.country && errors.country)}
                  fullWidth
                  required
                  helperText={touched.country && errors.country}
                  label="Land"
                  margin="normal"
                  name="country"
                  select
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.country}
                  variant="outlined"
                >
                  {countries.map((item) => (
                    <MenuItem
                      key={item.id}
                      value={item.id}
                    >
                      <Box
                        component="div"
                        mb={-0.7}
                        mt={-0.5}
                        alignItems="center"
                        display="flex"
                      >
                        <Country
                          countryShort={item.id}
                          size="32"
                        />
                        <Box
                          component="span"
                          ml={1}
                        >
                          {item.name}
                        </Box>
                      </Box>
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            </Grid>
          )}
          {/* ### END: Optional Business User Registration ### */}
          <Grid
            container
            spacing={1}
          >
            {values.businessUser && (
            <Grid
              item
              xs={12}
            >
              <Box
                alignItems="center"
                display="flex"
                mt={1}
                ml={-1}
              >
                <Checkbox
                  checked={values.useOpenAIForContent}
                  name="useOpenAIForContent"
                  onChange={handleChange}
                />
                <Typography
                  variant="body2"
                  color="textPrimary"
                >
                  Ich bin damit einverstanden, dass mein Firmenname an einen KI-Service weitergegeben wird, um mein Profil automatisch zu befüllen. Weitere Informationen finden Sie in unseren
                  {' '}
                  <Link
                    component="a"
                    href="/dataprivacy"
                    target="_blank"
                    rel="noopener noreferrer"
                    color="textSecondary"
                    fontWeight="bold"
                  >
                    Datenschutzbestimmungen
                  </Link>
                  .
                </Typography>
              </Box>
              {Boolean(touched.useOpenAIForContent && errors.useOpenAIForContent) && (
                <FormHelperText error>
                  {errors.useOpenAIForContent}
                </FormHelperText>
              )}
            </Grid>
            )}
            <Grid
              item
              xs={12}
            >
              <Box
                alignItems="center"
                display="flex"
                mt={1}
                ml={-1}
              >
                <Checkbox
                  checked={values.newsletter}
                  name="newsletter"
                  onChange={handleChange}
                />
                <Typography
                  variant="body2"
                  color="textPrimary"
                >
                  Ich möchte bitte
                  {' '}
                  <strong>keine</strong>
                  {' '}
                  Neuerungen und Aktionen über KUMAO erhalten.
                </Typography>
              </Box>
              {Boolean(touched.newsletter && errors.newsletter) && (
                <FormHelperText error>
                  {errors.newsletter}
                </FormHelperText>
              )}
            </Grid>
            <Grid
              item
              xs={12}
            >
              <Box
                alignItems="center"
                display="flex"
                mt={0}
                ml={-1}
              >
                <Checkbox
                  checked={values.policy}
                  name="policy"
                  required
                  onChange={handleChange}
                />
                <Typography
                  variant="body2"
                  color="textPrimary"
                >
                  Ich bin mit den
                  {' '}
                  <Link
                    component="a"
                    href="/terms-conditions"
                    target="_blank"
                    rel="noopener noreferrer"
                    color="textSecondary"
                    fontWeight="bold"
                  >
                    Geschäftsbedingungen
                    {' '}
                  </Link>
                  einverstanden!
                </Typography>
              </Box>
              <Box
                alignItems="center"
                display="flex"
                mt={0}
                ml={4.2}
              >
                <Typography
                  variant="body2"
                  color="textPrimary"
                >
                  Deine Daten sind schützenswert! Finde unter
                  {' '}
                  <Link
                    component="a"
                    href="/dataprivacy"
                    target="_blank"
                    rel="noopener noreferrer"
                    color="textSecondary"
                    fontWeight="bold"
                  >
                    Datenschutz
                    {' '}
                  </Link>
                  weitere Informationen dazu.
                </Typography>
              </Box>
              {Boolean(touched.policy && errors.policy) && (
                <FormHelperText error>
                  {errors.policy}
                </FormHelperText>
              )}
              {errors.submit && (
                <Box mt={1}>
                  <FormHelperText error>
                    {errors.submit}
                  </FormHelperText>
                </Box>
              )}
            </Grid>
            <Grid
              item
              xs={12}
            >

              <Box mt={2}>
                <Button
                  color="primary"
                  disabled={isSubmitting}
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                >
                  Registrieren
                </Button>
              </Box>
            </Grid>
          </Grid>
          <UserMessageSnackbar
            open={showErrorText}
            text={errorText}
            severity="error"
            onClose={handleCloseSnackbar}
          />
        </form>
      )}
    </Formik>
  );
};

export default RegisterJWT;
