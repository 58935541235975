import { useState, useEffect, useCallback } from 'react';
import type { FC } from 'react';
import { Helmet } from 'react-helmet-async';
import {
  Container,
  Link,
  Typography,
  Breadcrumbs,
  Stack,
} from '@mui/material';
import { useParams, useNavigate, Link as RouterLink, useLocation } from 'react-router-dom';
import useIsMountedRef from 'src/hooks/useIsMountedRef';
import axios from 'src/lib/axios';
import { OrganizationDTO } from 'src/api/dto/organization/organization';
import { useTheme } from '@mui/material/styles';
import { branches } from 'src/dummydata/branches';
import useSettings from 'src/hooks/useSettings';
import DateHelper from 'src/utils/dateHelper';
import useSession from 'src/hooks/useSession';
import { SelectedService } from 'src/types/selectedService';
import { UserDataFields } from 'src/components/booking/confirmation/UserDataFields';
import { CalendarBookingDTO } from 'src/api/dto/booking/calendarBooking';
import UserMessageSnackbar from 'src/components/widgets/userMessage/UserMessageSnackbar';
import WaitDialog from 'src/components/widgets/overlays/WaitDialog';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { OfferedEventDTO } from 'src/api/dto/organization/offeredEvent';
import { CalendarBookingEventDTO } from 'src/api/dto/booking/calendarBookingEvent';
import { UserDataCustomizedFields } from 'src/components/booking/customizedFields/UserDataCustomizedFields';
import { EventType } from 'src/api/dto/organization/enEventType';
import { BookingSuccessState } from 'src/types/booking/bookingSuccessState';
import generateUrlFriendly from 'src/utils/generateUrlFriendly';
import { customDomain, isCustomDomain } from 'src/hooks/useCustomDomain';
import NotFound from '../NotFound';
import {
  BookingSelection,
  BookingConfirmation
} from '../../components/booking';

const Booking: FC = () => {
  const theme = useTheme();
  const isMountedRef = useIsMountedRef();
  const { organizationId } = useParams();
  const { settings } = useSettings();
  const navigate = useNavigate();
  const location = useLocation();
  const [invalidOrganization, setInvalidOrganization] = useState<Boolean>(false);
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const [openWaitDialog, setOpenWaitDialog] = useState<boolean>(false);
  const [snackbarText, setSnackbarText] = useState<string>('');
  const [showConfirmation, setshowConfirmation] = useState<Boolean>(false);
  const [colorCode, setColorCode] = useState<string>(theme.palette.primary.main);
  const [selectedServices, setSelectedServices] = useState<SelectedService[]>([]);
  const [selectedEvent, setSelectedEvent] = useState<OfferedEventDTO>(null);
  const { session, saveSession } = useSession();
  const [organization, setOrganization] = useState<OrganizationDTO | null>(
    null
  );
  const [organizationNr, setOrganizationNr] = useState<number>();

  const parseOrganizationNrFromString = (id:String) => {
    if (!id.match(/\d+$/)) { return 0; }
    const result = id.match(/\d+$/)[0];
    return +result;
  };

  // const organizationNr = parseOrganizationNrFromString(organizationId);

  const handleConfirm = (myselectedServices: SelectedService[]) => {
    setSelectedServices(myselectedServices);
    setshowConfirmation(true);
    window.scrollTo(0, 0);
  };

  const showSelection = () => {
    setshowConfirmation(false);
    window.scrollTo(0, 0);
  };

  const getOrganization = useCallback(async (id: number) => {
    setInvalidOrganization(false);
    try {
      const response = await axios.get<OrganizationDTO>(
        `/api/organization/${id}`
      );

      if (isMountedRef.current) {
        setOrganization(response.data);

        if (response.data.branches && response.data.branches.length > 0) {
          const selectedBranch = branches.find((x) => response.data.branches.some((x2) => x2.id === x.id));
          if (selectedBranch) {
            setColorCode(selectedBranch.color);
          }
        }
      }
    } catch (err) {
      setInvalidOrganization(true);
      console.error(err);
    }
  }, [isMountedRef]);

  const getOrganizationByDomain = useCallback(async (domain: string) => {
    setInvalidOrganization(false);
    try {
      const response = await axios.get<OrganizationDTO>(
        `/api/organization/domain/${domain}`
      );

      if (isMountedRef.current) {
        const orgNum = response.data.organizationNr;

        setOrganizationNr(orgNum);

        if (response.data.branches && response.data.branches.length > 0) {
          const selectedBranch = branches.find((x) => response.data.branches.some((x2) => x2.id === x.id));
          if (selectedBranch) {
            setColorCode(selectedBranch.color);
          }
        }
      }
    } catch (err) {
      setInvalidOrganization(true);
      console.error(err);
    }
  }, [isMountedRef]);

  const onBookService = useCallback(async (userDataValues: UserDataFields, services: SelectedService[], org: OrganizationDTO, userDataValuesCustomized?: UserDataCustomizedFields[]) => {
    setOpenWaitDialog(true);
    session.userData = userDataValues;
    saveSession({
      ...session
    });
    try {
      if (!services && services.length === 0) {
        throw new Error('Es wurden keine Services ausgewählt!');
      }
      const entry: CalendarBookingDTO = {
        customerData: userDataValues,
        location: '',
        organizatonNr: org.organizationNr,
        services: services.map((item) => ({ serviceId: item.service.serviceID, userId: item.user ? +item.user.userId : null, start: DateHelper.toISOLocalDateTime(item.selectedSlot) })),
        customizedFields: userDataValuesCustomized,
      };
      const response = await axios.post<string>('/api/calendar/addbooking', entry);
      if (isMountedRef.current) {
        // delete services from cart
        session.selectedServices = session.selectedServices.filter((item) => item.service.organizationNr !== org.organizationNr);
        saveSession({
          ...session
        });

        const bookingSuccessState: BookingSuccessState = {
          icsEntry: response.data,
          organization: org,
          selectedServices: services,
          selectedEvent: null
        };

        navigate('/booking/success', { state: bookingSuccessState });
      }
    } catch (err) {
      setOpenSnackbar(true);
      setSnackbarText(err.message);
      console.error(err);
    } finally {
      setOpenWaitDialog(false);
    }
    return true;
  }, [isMountedRef]);

  const onBookEvent = useCallback(async (userDataValues: UserDataFields, event: OfferedEventDTO, org: OrganizationDTO) => {
    setOpenWaitDialog(true);
    try {
      if (!event) {
        throw new Error('Es wurde kein Event ausgewählt!');
      }
      const entry: CalendarBookingEventDTO = {
        customerData: userDataValues,
        location: '',
        organizatonNr: org.organizationNr,
        eventID: event.eventID,
        schedules: event.schedules,
      };

      const response = await axios.post<string>('/api/calendar/addeventbooking', entry);
      if (isMountedRef.current) {
        const bookingSuccessState: BookingSuccessState = {
          icsEntry: response.data,
          organization: org,
          selectedServices: null,
          selectedEvent: event
        };

        navigate('/booking/success', { state: bookingSuccessState });
      }
    } catch (err) {
      setOpenSnackbar(true);
      setSnackbarText(err.message);
      console.error(err);
    } finally {
      setOpenWaitDialog(false);
    }
    return true;
  }, [isMountedRef]);

  const onBook = (userDataValues: UserDataFields, services: SelectedService[], event: OfferedEventDTO, org: OrganizationDTO, userDataValuesCustomized: UserDataCustomizedFields[]) => {
    if (event) {
      onBookEvent(userDataValues, event, org);
    } else {
      onBookService(userDataValues, services, org, userDataValuesCustomized);
    }
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackbar(false);
  };

  useEffect(() => {
    if (organizationNr) {
      getOrganization(organizationNr);
    }
  }, [organizationNr]);

  useEffect(() => {
    if (isCustomDomain()) {
      getOrganizationByDomain(customDomain());
    } else {
      setOrganizationNr(parseOrganizationNrFromString(organizationId));
    }
  }, [getOrganizationByDomain]);

  useEffect(() => {
    if (location && location.state) { // TODO Add typecheck for location.state
      setSelectedEvent(location.state as OfferedEventDTO);
      setshowConfirmation(true);
    }
  }, [isMountedRef]);

  if (invalidOrganization) {
    return <NotFound />;
  }

  if (!organization) {
    return null;
  }

  const getTitleText = (event:OfferedEventDTO) => {
    if (!event) {
      return 'Termin reservieren';
    }
    if (event.eventType === EventType.Event) {
      return 'Anmelden zum Event';
    }

    return 'Anmelden zum Kurs';
  };

  const breadcrumbs = [
    <Link
      component={RouterLink}
      underline="hover"
      key="1"
      color="inherit"
      to={generateUrlFriendly(organization.companyName, organization.organizationNr)}
    >
      {organization.companyName}
    </Link>,
    <Typography
      key="3"
      color="text.primary"
    >
      {getTitleText(selectedEvent)}
    </Typography>,
  ];

  const getPageTitle = () => {
    if (isCustomDomain()) {
      return organization.companyName;
    }
    return `Kumao | ${organization.companyName}`;
  };

  return (
    <>
      <Helmet>
        <title>
          {getPageTitle()}
        </title>
      </Helmet>
      <Container
        maxWidth={settings.compact ? 'lg' : false}
        sx={{ pt: 4 }}
      >
        <Stack spacing={2}>
          <Breadcrumbs
            separator={<NavigateNextIcon fontSize="small" />}
            aria-label="breadcrumb"
          >
            {breadcrumbs}
          </Breadcrumbs>
        </Stack>
        { !showConfirmation && (
          <BookingSelection
            organization={organization}
            organizationNr={organizationNr}
            colorCode={colorCode}
            onConfirm={handleConfirm}
          />
        )}
        { showConfirmation && (
          <BookingConfirmation
            organization={organization}
            colorCode={colorCode}
            selectedServices={selectedServices}
            selectedEvent={selectedEvent}
            onShowSelection={showSelection}
            onBookSlot={(userFields, userDataValuesCustomized) => onBook(userFields, selectedServices, selectedEvent, organization, userDataValuesCustomized)}
          />
        )}
        <UserMessageSnackbar
          open={openSnackbar}
          onClose={handleCloseSnackbar}
          text={snackbarText}
          severity="error"
        />
        <WaitDialog
          open={openWaitDialog}
          showButton={false}
        />
      </Container>
    </>
  );
};

export default Booking;
