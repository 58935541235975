import { useState } from 'react';
import type { FC, ReactNode } from 'react';
import PropTypes from 'prop-types';
import { Outlet } from 'react-router-dom';
import { experimentalStyled } from '@mui/material/styles';
// import useSettings from 'src/hooks/useSettings';
import { isCustomDomain } from 'src/hooks/useCustomDomain';
import useSession from 'src/hooks/useSession';
import Footer from './Footer';
import MainNavbar from './MainNavbar';
import MainSidebar from './MainSidebar';
import CustomDomainFooter from './CustomDomainFooter';
// import CookieDialog from './widgets/overlays/CookieDialog';

interface MainLayoutProps {
  children?: ReactNode;
}

const MainLayoutRoot = experimentalStyled('div')(
  ({ theme }) => (
    {
      backgroundColor: theme.palette.background.default,
      height: '100%',
      paddingTop: isCustomDomain() ? 0 : 64
    }
  )
);

const MainLayout: FC<MainLayoutProps> = ({ children }) => {
  // const { settings } = useSettings();
  const [isSidebarMobileOpen, setIsSidebarMobileOpen] = useState<boolean>(false);
  const customDomain = isCustomDomain();
  const { session } = useSession();
  // const [isCookieDialogOpen, setIsCookieDialogOpen] = useState<boolean>(!settings?.cookieSettings?.requiredAccepted);

  // const handleCookieDialogClose = () => {
  //  setIsCookieDialogOpen(false);
  // };
  return (
    <MainLayoutRoot>
      {!customDomain
        && (
        <MainNavbar
          onSidebarMobileOpen={(): void => setIsSidebarMobileOpen(true)}
        />
        )}
      {!customDomain
        && (
        <MainSidebar
          onMobileClose={(): void => setIsSidebarMobileOpen(false)}
          openMobile={isSidebarMobileOpen}
        />
        )}
      {children || <Outlet />}
      {/* disabled because we do not use cookies at the moment
      <CookieDialog
        open={isCookieDialogOpen}
        onClose={handleCookieDialogClose}
      />
      */}
      {!customDomain && (<Footer />)}
      {customDomain && (<CustomDomainFooter orgNum={session.lastViewedOrganizations.length > 0 ? session.lastViewedOrganizations[0] : 0} />)}
    </MainLayoutRoot>
  );
};

MainLayout.propTypes = {
  children: PropTypes.node
};

export default MainLayout;
