import { useState, useEffect, useRef, useCallback } from 'react';
import type { FC } from 'react';
import {
  Container,
  Grid,
  Typography,
  TextField,
  Button
} from '@mui/material';
import useIsMountedRef from 'src/hooks/useIsMountedRef';
import { styled } from '@mui/material/styles';
import useSession from 'src/hooks/useSession';
import useAuth from 'src/hooks/useAuth';
import { isCustomDomain } from 'src/hooks/useCustomDomain';
import * as Yup from 'yup';
import phoneRegExp from 'src/utils/regexExp';
import { useBeforeUnload } from 'react-router-dom';
import { Formik } from 'formik';
import { LoginForm } from 'src/components/authentication/login';
import { UserDataFields } from './UserDataFields';

interface UserDataProps {
  submitForm: boolean;
  submitUserData: (userDataValues: UserDataFields) => boolean;
  onChangeShowLogin: (showLogin: boolean) => void;
  showRemarks?: boolean;
  phoneMandatory?: boolean;
  showPhone?: boolean;
}

const UserData: FC<UserDataProps> = (props) => {
  const { submitForm, submitUserData, onChangeShowLogin, showRemarks, phoneMandatory, showPhone } = props;
  const isMountedRef = useIsMountedRef();
  const { session, saveSession } = useSession();
  const { isAuthenticated, user } = useAuth();
  const [userDataValues, setUserDataValues] = useState<UserDataFields | null>(null);
  const [showLogin, setShowLogin] = useState<boolean>(false);

  const myForm = useRef(null);

  const CustomFieldBox = styled(TextField)(() => ({
    '& fieldset': {
      borderRadius: '25px',
    },
  }));

  useEffect(() => {
    onChangeShowLogin(showLogin);
  }, [showLogin]);

  useEffect(() => {
    if (session.userData !== null && session.userData !== undefined) {
      setUserDataValues(session.userData);
      return;
    }

    if (isAuthenticated) {
      const myValues : UserDataFields = { firstName: user.firstName, lastName: user.lastName, userEmail: user.email, userPhone: '', userRemarks: '' };
      setUserDataValues(myValues);
      return;
    }

    const myValues : UserDataFields = { firstName: '', lastName: '', userEmail: '', userPhone: '', userRemarks: '' };
    setUserDataValues(myValues);
  }, [isMountedRef]);

  useEffect(() => {
    if (submitForm) {
      myForm.current.submitForm();
    }
  }, [submitForm]);

  useBeforeUnload(
    useCallback(() => {
      saveSession({
        ...session
      });
    }, [])
  );

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const customHandleBlur = (e: any) => {
    const myValues : UserDataFields = { firstName: myForm.current.values.firstName, lastName: myForm.current.values.lastName, userEmail: myForm.current.values.email, userPhone: myForm.current.values.phone, userRemarks: myForm.current.values.remark };
    session.userData = myValues;
  };

  const handleLoginClick = () => {
    setShowLogin(!showLogin);
  };

  useEffect(() => {
    if (isAuthenticated) {
      setShowLogin(false);
      if (!session || !session.userData || (session.userData.firstName === '' && session.userData.lastName === '')) {
        const myValues : UserDataFields = { firstName: user.firstName, lastName: user.lastName, userEmail: user.email, userPhone: '', userRemarks: '' };
        setUserDataValues(myValues);
      }
      // console.log(myValues);
    }
  }, [isAuthenticated]);

  return (
    <>
      {!isAuthenticated && !showLogin && !isCustomDomain() && (
      <Container sx={{ mb: 4 }}>
        <Button
          onClick={handleLoginClick}
        >
          <Typography
            color="primary"
            sx={{ textDecoration: 'underline', fontFamily: 'Roboto', fontWeight: 700 }}
          >
            Anmelden
          </Typography>
        </Button>
        <Typography
          display="inline"
          sx={{ fontFamily: 'Roboto', fontWeight: 700 }}
        >
          oder als Gast fortfahren
        </Typography>
      </Container>
      )}
      { !isAuthenticated && showLogin && (
      <Container sx={{ mb: 4 }}>

        <Typography
          display="inline"
          sx={{ fontFamily: 'Roboto', fontWeight: 700 }}
        >
          Anmelden oder
        </Typography>
        <Button
          onClick={handleLoginClick}
        >
          <Typography
            display="inline"
            sx={{ textDecoration: 'underline', fontFamily: 'Roboto', fontWeight: 700 }}
          >
            als Gast fortfahren
          </Typography>
        </Button>
      </Container>
      )}
      {!isAuthenticated && showLogin && (
      <LoginForm
        embedded
        redirectTo={window.location.pathname}
      />
      )}
      {(isAuthenticated || !showLogin) && (
      <Container sx={{ borderRadius: '20px', boxShadow: '0px -5px 18px rgba(0, 0, 0, 0.1)', pt: isAuthenticated ? 1 : 0 }}>
        <Formik
          enableReinitialize
          innerRef={myForm}
          initialValues={{
            email: userDataValues?.userEmail ?? '',
            firstName: userDataValues?.firstName ?? '',
            lastName: userDataValues?.lastName ?? '',
            phone: userDataValues?.userPhone ?? '',
            remark: userDataValues?.userRemarks ?? '',
            submit: null
          }}
          validationSchema={Yup.object().shape({
            email: Yup.string().email('Bitte gib eine gültige E-Mail Adresse an!').max(255).required('*erforderlich'),
            firstName: Yup.string().max(255).required('*erforderlich'),
            lastName: Yup.string().max(255).required('*erforderlich'),
            phone: phoneMandatory
              ? Yup.string().matches(phoneRegExp, 'Ungültige Telefonnummer!').required('*erforderlich')
              : Yup.string().matches(phoneRegExp, 'Ungültige Telefonnummer!').notRequired(),
            remark: Yup.string().max(255).notRequired()
          })}
          onSubmit={async (values, {
            setStatus,
            setSubmitting
          }) => {
            try {
              userDataValues.userEmail = values.email;
              userDataValues.firstName = values.firstName;
              userDataValues.lastName = values.lastName;
              userDataValues.userPhone = values.phone;
              userDataValues.userRemarks = values.remark;

              if (submitUserData(userDataValues)) {
                setStatus({ success: true });
                setSubmitting(false);
              }
            } catch (err) {
              console.error(err);
              setStatus({ success: false });
              setSubmitting(false);
            }
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            touched,
            values,
          }): JSX.Element => (
            <form
              onSubmit={handleSubmit}
            >
              <Grid
                container
                spacing={2}
                sx={{ p: 2, pb: 4 }}
              >
                <Grid
                  item
                  xs={12}
                  sx={{ pb: 5 }}
                >
                  <Typography sx={{ color: '#1B1D21', fontSize: 28, fontFamily: 'Rubik', fontWeight: 600 }}>Deine Daten</Typography>

                </Grid>
                <Grid
                  item
                  xs={12}
                  md={6}
                >
                  <CustomFieldBox
                    label="Vorname"
                    variant="outlined"
                    required
                    fullWidth
                    helperText={touched.firstName && errors.firstName}
                    inputProps={{ maxLength: 50 }}
                    name="firstName"
                    onBlur={(e) => {
                      customHandleBlur(e);
                      handleBlur(e);
                    }}
                    onChange={handleChange}
                    value={values.firstName}
                    error={Boolean(touched.firstName && errors.firstName)}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={6}
                >
                  <CustomFieldBox
                    label="Nachname"
                    variant="outlined"
                    required
                    fullWidth
                    helperText={touched.lastName && errors.lastName}
                    inputProps={{ maxLength: 50 }}
                    name="lastName"
                    onBlur={(e) => {
                      customHandleBlur(e);
                      handleBlur(e);
                    }}
                    onChange={handleChange}
                    value={values.lastName}
                    error={Boolean(touched.lastName && errors.lastName)}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={6}
                >
                  <CustomFieldBox
                    label="Email"
                    variant="outlined"
                    fullWidth
                    required
                    helperText={touched.email && errors.email}
                    inputProps={{ maxLength: 100 }}
                    name="email"
                    onBlur={(e) => {
                      customHandleBlur(e);
                      handleBlur(e);
                    }}
                    onChange={handleChange}
                    value={values.email}
                    error={Boolean(touched.email && errors.email)}
                  />
                </Grid>
                {showPhone && (
                <Grid
                  item
                  xs={12}
                  md={6}
                >
                  <CustomFieldBox
                    label={phoneMandatory ? 'Telefonnummer' : 'Telefonnummer (optional)'}
                    variant="outlined"
                    fullWidth
                    helperText={touched.phone && errors.phone}
                    inputProps={{ maxLength: 50 }}
                    name="phone"
                    onBlur={(e) => {
                      customHandleBlur(e);
                      handleBlur(e);
                    }}
                    onChange={handleChange}
                    value={values.phone}
                    required={phoneMandatory}
                    error={Boolean(touched.phone && errors.phone)}
                  />
                </Grid>
                )}
                {showRemarks && (
                <Grid
                  item
                  xs={12}
                >
                  <CustomFieldBox
                    label="Anmerkungen zum Termin (optional)"
                    variant="outlined"
                    fullWidth
                    helperText={touched.remark && errors.remark}
                    inputProps={{ maxLength: 1000 }}
                    name="remark"
                    onBlur={(e) => {
                      customHandleBlur(e);
                      handleBlur(e);
                    }}
                    onChange={handleChange}
                    value={values.remark}
                    error={Boolean(touched.remark && errors.remark)}
                    minRows={2}
                    multiline
                  />
                </Grid>
                )}
              </Grid>
            </form>
          )}
        </Formik>
      </Container>
      )}
    </>
  );
};

UserData.defaultProps = {
  showRemarks: true,
  showPhone: true,
  phoneMandatory: false,
};

export default UserData;
