import {
  FC, useEffect, useState
} from 'react';
import {
  Card,
  CardContent,
  CardMedia,
  Box,
  Link,
  Grid,
  Typography,
  Button,
  useMediaQuery,
  Theme,
  // Skeleton,
} from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import parse from 'html-react-parser';
import { serverConfig } from 'src/config';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
// import LocationOnIcon from '@mui/icons-material/LocationOn';
import Collapse from '@mui/material/Collapse';
import { OrganizationCard } from 'src/api/dto/search/OrganizationCard';
import { styled } from '@mui/material/styles';
import findBranchColor from 'src/utils/findBranchColor';
import axios from 'src/lib/axios';
import { OrganizationDTO } from 'src/api/dto/organization/organization';
import { OrganizationListItemDTO } from 'src/api/dto/organization/organizationListItem';
import { OrganizationWebContentDTO } from 'src/api/dto/webContent/webContent';
import { WebContentLocation } from 'src/api/dto/webContent/webContentLocation';
// import useIsMountedRef from 'src/hooks/useIsMountedRef';
import generateUrlFriendly from 'src/utils/generateUrlFriendly';
import OpenNow from '../widgets/openingHours/OpenNow';
import ExpandMore from '../widgets/expands/ExpandMore';

interface OrgProps {
  orgCardSearch?: OrganizationCard,
  orgCardDTO?: OrganizationDTO,
  orgCardListItemDTO?: OrganizationListItemDTO
  typ?: 'default' | 'fav' | 'map';
}

const OrgCard: FC<OrgProps> = (props) => {
  const { orgCardSearch, orgCardDTO, orgCardListItemDTO, typ } = props;
  const [expanded, setExpanded] = useState(false);
  // const [distance, setDistance] = useState('0km');
  // const [calculatingDistance, setCalculatingDistance] = useState(false);
  const [orgCard, setOrgCard] = useState<OrganizationCard | null>(null);
  // const isMountedRef = useIsMountedRef();
  const isSmScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  let MainCard;
  let MediaImg;
  let CardContentExpand;
  let CompNameBox;

  if (typ === 'fav') {
    MainCard = styled(Card)({
      position: 'relative',
      width: 220,
      margin: 1,
      borderRadius: 10
    });

    MediaImg = styled(CardMedia)({
      width: 200,
      height: 110,
      borderTopRightRadius: 10
    });

    CardContentExpand = styled(CardContent)({
      position: 'absolute',
      height: '100%',
      right: 0,
      width: 200,
      zIndex: 1010,
      paddingTop: 7
    });

    CompNameBox = styled(Box)({
      whiteSpace: 'nowrap',
      width: 166
    });
  } else if (typ === 'map') {
    MainCard = styled(Card)({
      position: 'relative',
      width: 180,
      margin: 1,
      borderRadius: 10
    });

    MediaImg = styled(CardMedia)({
      width: 170,
      height: 80,
      borderTopRightRadius: 10
    });

    CardContentExpand = styled(CardContent)({
      position: 'absolute',
      height: '100%',
      right: 0,
      width: 160,
      zIndex: 1010,
      paddingTop: 7
    });

    CompNameBox = styled(Box)({
      whiteSpace: 'nowrap',
      width: 136
    });
  } else if (isSmScreen) {
    MainCard = styled(Card)({
      position: 'relative',
      width: 220,
      borderRadius: 16,
      margin: '8px auto 8px auto',
    });

    MediaImg = styled(CardMedia)({
      maxHeight: 110,
      borderTopRightRadius: 16,
      width: '100%',
      objectFit: 'fill'
    });

    CardContentExpand = styled(CardContent)({
      position: 'absolute',
      height: '100%',
      right: 0,
      width: '100%',
      zIndex: 1010,
      paddingTop: 7
    });

    CompNameBox = styled(Box)({
      whiteSpace: 'nowrap',
      width: 196
    });
  } else {
    MainCard = styled(Card)({
      position: 'relative',
      width: 240,
      margin: 4,
      borderRadius: 16
    });

    MediaImg = styled(CardMedia)({
      width: 220,
      height: 130,
      borderTopRightRadius: 16
    });

    CardContentExpand = styled(CardContent)({
      position: 'absolute',
      height: '100%',
      right: 0,
      width: 220,
      zIndex: 1010,
      paddingTop: 7
    });

    CompNameBox = styled(Box)({
      whiteSpace: 'nowrap',
      width: 196
    });
  }

  const buildOrgCard = () => {
    let tmpOrdCard: OrganizationCard;

    if (orgCardSearch) {
      tmpOrdCard = orgCardSearch;
    } else if (orgCardDTO) {
      tmpOrdCard = {
        organization_nr: orgCardDTO.organizationNr,
        company_name: orgCardDTO.companyName,
        logo_url: orgCardDTO.logo,
        branches: orgCardDTO.branches,
        latitude: orgCardDTO.latitude,
        longitude: orgCardDTO.longitude,
        city: orgCardDTO.city
      };
    } else if (orgCardListItemDTO) {
      tmpOrdCard = {
        organization_nr: orgCardListItemDTO.organizationNr,
        company_name: orgCardListItemDTO.companyName,
        logo_url: orgCardListItemDTO.logo,
        branches: orgCardListItemDTO.branches,
        latitude: orgCardListItemDTO.latitude,
        longitude: orgCardListItemDTO.longitude,
        city: orgCardListItemDTO.city
      };
    }

    setOrgCard(tmpOrdCard);
  };

  const handleExpandClick = async () => {
    if (!expanded && !orgCard.html_slogan) {
      try {
        const response = await axios.get<OrganizationWebContentDTO>(
          `/api/webcontent/${orgCard.organization_nr}/${WebContentLocation.ProfileOverviewSlogan}`
        );

        orgCard.html_slogan = response.data.htmlContent;
      } catch (err) {
        console.error(err);
      }
    }

    setExpanded(!expanded);
  };

  useEffect(() => {
    buildOrgCard();
  }, []);

  /* useEffect(() => {
    if (orgCard) {
      if (navigator.geolocation) {
        setCalculatingDistance(true);
        navigator.geolocation.getCurrentPosition((geoPosition) => {
          const dx = 71.5 * (geoPosition.coords.longitude - orgCard.longitude);
          const dy = 111.3 * (geoPosition.coords.latitude - orgCard.latitude);
          let distanceCalc = dx * dx - dy * dy;
          if (distanceCalc < 0) {
            distanceCalc *= -1;
          }
          distanceCalc = Math.sqrt(distanceCalc);
          let distanceString;

          if (distanceCalc > 99) {
            distanceString = '>99km';
          } else if (distanceCalc < 1) {
            distanceCalc = Math.round(distanceCalc * 100);
            distanceString = `${distanceCalc}m`;
          } else {
            distanceCalc = Math.round(distanceCalc);
            distanceString = `${distanceCalc}km`;
          }

          if (isMountedRef.current) {
            setDistance(distanceString);
            setCalculatingDistance(false);
          } else {
            console.warn('Orgcard component is not mounted anymore.');
          }
        }, () => setCalculatingDistance(false));
      } else {
        console.warn('Geolocation is not supported by this browser.');
      }
    }
  }, [isMountedRef, orgCard]);
  */

  if (!orgCard) { return null; }

  return (
    <MainCard raised={typ !== 'fav'}>
      <Grid
        container
        alignItems="stretch"
      >
        <Collapse
          in={expanded}
          timeout="auto"
          unmountOnExit
        >
          <CardContentExpand
            sx={{ backgroundColor: findBranchColor(orgCard.branches) }}
            onClick={handleExpandClick}
          >
            {orgCard.branches?.length > 0 && (
            <Typography
              gutterBottom
              variant="body2"
              sx={{ color: 'white' }}
            >
              {orgCard.branches[0].name}
            </Typography>
            )}
            <Typography
              variant="h6"
              color="text.secondary"
              sx={{ color: 'white' }}
            >
              {orgCard.company_name}
            </Typography>
            <Typography
              // paragraph
              component="div"
              sx={{ color: 'white' }}
            >
              {orgCard.html_slogan && <Box py={1}>{parse(orgCard.html_slogan)}</Box>}
            </Typography>
          </CardContentExpand>

        </Collapse>
        <Grid
          item
          sx={{
            backgroundColor: findBranchColor(orgCard.branches),
            width: 20,
            cursor: 'pointer'
          }}
          onClick={handleExpandClick}
        >
          <ExpandMore
            expand={expanded}
            aria-expanded={expanded}
            sx={{ pl: 0.2, pt: 0.8 }}
          >
            <PlayArrowIcon sx={{ width: 18, color: 'white' }} />
          </ExpandMore>
        </Grid>
        <Grid
          item
          sx={{ width: 'calc(100% - 20px)' }}
        >
          <Link
            key={orgCard.organization_nr}
            color="textSecondary"
            component={RouterLink}
            to={generateUrlFriendly(orgCard.company_name, orgCard.organization_nr)}
            underline="none"
            variant="body2"
          >
            {orgCard.logo_url && (
            <MediaImg
              component="img"
              image={serverConfig.baseIMGUrl + orgCard.logo_url}
            />
            )}
            {!orgCard.logo_url && (
            <CardMedia
              component="img"
              image="/static/mock-images/logoipsum-241.svg"
              sx={{ width: 200,
                height: 130,
                borderTopRightRadius: 10,
                pl: 2 }}
            />
            )}

          </Link>
          <CardContent sx={{ paddingTop: 0, paddingLeft: 1, marginBottom: 0 }}>
            <Link
              key={orgCard.organization_nr}
              color="textSecondary"
              component={RouterLink}
              to={generateUrlFriendly(orgCard.company_name, orgCard.organization_nr)}
              underline="none"
              variant="body2"
            >
              <CompNameBox
                component="div"

              >
                <Typography
                  variant="h6"
                  color="text.secondary"
                  sx={{
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                  }}
                >
                  {orgCard.company_name}
                </Typography>
              </CompNameBox>
              <Grid
                container
                justifyContent="space-between"
              >
                <Grid
                  item
                >
                  <Typography
                    variant="body2"
                    color="text.secondary"
                  >
                    {orgCard.city}
                  </Typography>
                </Grid>
                {/* distance !== '0km' && (
                <Grid
                  item
                >
                  <Typography sx={{
                    fontSize: 14,
                  }}
                  >
                    <LocationOnIcon
                      sx={{
                        height: 12,
                      }}
                    />
                    {distance}
                  </Typography>
                </Grid>
                ) */}
                {/* calculatingDistance && (
                <Grid
                  item
                >
                  <Skeleton
                    variant="text"
                    width={35}
                    height={25}
                  />
                </Grid>
                ) */}
              </Grid>
            </Link>
            <Box sx={{ pt: 1 }}>
              <OpenNow
                organizationId={orgCard.organization_nr}
                showAllSlots={false}
                sx={{ minHeight: 30 }}
              />
            </Box>
            <Link
              component={RouterLink}
              to={generateUrlFriendly(orgCard.company_name, orgCard.organization_nr)}
              underline="none"
            >
              <Box sx={{ width: '100%', textAlign: 'center', pt: 2 }}>
                {(typ !== 'map' && (
                <Button
                  variant="outlined"
                  aria-label="Termin reservieren"
                  sx={{ borderRadius: 7, marginTop: 1 }}
                >
                  Termin reservieren
                </Button>
                ))}
              </Box>
            </Link>
          </CardContent>
        </Grid>
      </Grid>
    </MainCard>

  );
};

OrgCard.defaultProps = {
  typ: 'default'
};

export default OrgCard;
