import React, {
  FC,
  useCallback,
  useEffect,
  useState
} from 'react';
import {
  Box,
  Container,
  Divider,
  Grid,
  Link,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
  Button
} from '@mui/material';
import { alpha } from '@mui/material/styles';
import { Link as RouterLink } from 'react-router-dom';
import { serverConfig } from 'src/config';
import useIsMountedRef from 'src/hooks/useIsMountedRef';
import { SocialMediaDTO } from 'src/api/dto/socialmedia/socialMedia';
import axios from 'src/lib/axios';
import { EnSocialMediaType } from 'src/api/dto/socialmedia/enSocialMediaType';
import KumaoATkumao from './emailaddress/KumaoATkumao';
import MinusIcon from '../icons/Minus';
import Logo from './Logo';
import SocialMediaIcons from './widgets/social/SocialMediaIcons';

interface CustomDomainFooterProps {
  orgNum: number;
}

const defaultSection = [
  {
    title: 'Infos',
    links: [

    ],
  },
];

const Footer: FC<CustomDomainFooterProps> = (props) => {
  const { orgNum } = props;

  const [showingEmail, setShowingEmail] = useState(false);
  const [sections, setSections] = useState(defaultSection);
  const isMountedRef = useIsMountedRef();

  const getContactInformation = useCallback(async (orgID: number) => {
    try {
      const response = await axios.get<SocialMediaDTO[]>(
        '/api/socialmedia', { params: { organizationnr: orgID } }
      );
      if (isMountedRef.current) {
        const newSection = {
          title: 'Social',
          links: []
        };
        response.data.forEach((item) => {
          switch (item.enumSocialMediaType) {
            case EnSocialMediaType.Discord:
            case EnSocialMediaType.Facebook:
            case EnSocialMediaType.Email:
            case EnSocialMediaType.Instagram:
            case EnSocialMediaType.LinkedIn:
            case EnSocialMediaType.Pinterest:
            case EnSocialMediaType.Snapchat:
            case EnSocialMediaType.TikTok:
            case EnSocialMediaType.Twitter:
            case EnSocialMediaType.Youtube:
              newSection.links.push(
                {
                  title: item.enumSocialMediaType,
                  href: item.website,
                  target: '_blank',
                  icon: item.enumSocialMediaType
                }
              );
              break;

            default:
              // ignore not mapped types
          }
        });

        const newList = sections.slice(0);
        newList.push(newSection);
        setSections(newList);
      }
    } catch (err) {
      console.error(err);
    }
  }, [isMountedRef]);

  const email = showingEmail ? (
    <KumaoATkumao />
  ) : (
    <Button
      onClick={() => setShowingEmail(true)}
      color="secondary"
      size="small"
      variant="contained"
    >
      Kontakt E-Mail
    </Button>
  );

  useEffect(() => {
    if (orgNum > 0) {
      getContactInformation(orgNum);
    }
  }, [getContactInformation, orgNum]);

  return (
    <Box
      sx={{
        backgroundColor: '#F5F5F7', // 'background.default',
        pb: 6,
        pt: {
          md: 10,
          xs: 2
        },
        mt: 5
      }}
    >
      <Container maxWidth="lg">
        <Grid
          container
          spacing={3}
        >
          <Grid
            item
            md={4}
            sm={4}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              order: {
                md: 1,
                xs: 4
              }
            }}
            xs={12}
          >
            <Typography variant="body2">Powered by</Typography>
            <RouterLink to={`https://${serverConfig.hostname}/`}>
              <Logo />
            </RouterLink>
            <Box sx={{ mt: 1 }}>
              <Typography
                color="textSecondary"
                variant="caption"
              >
                <React.Suspense fallback={<div>loading...</div>}>
                  <Box
                    sx={{
                      ml: 3
                    }}
                  >
                    {email}
                  </Box>
                </React.Suspense>
              </Typography>
            </Box>
          </Grid>
          {sections.map((section, index) => (
            <Grid
              item
              key={section.title}
              md={4}
              sm={4}
              sx={{
                order: {
                  md: index + 2,
                  xs: index + 1
                }
              }}
              xs={12}
            >
              <Typography
                color="textSecondary"
                variant="overline"
              >
                {section.title}
              </Typography>
              <List disablePadding>
                {section.title === 'Infos' && (
                <ListItem disableGutters>
                  <Box>
                    <Link
                      href="https://www.wkoecg.at/Ecg.aspx?FirmaID=3c217346-4796-4f74-9256-b0e6b5cdf0e2"
                      target="_blank"
                    >
                      <img
                        src="/static/ecg_logo.avif"
                        alt="ECG Logo"
                        width="340"
                        height="48"
                      />
                      <Typography p={1}>Alle Impressums- und Offenlegungsangaben werden mit dem Link erfüllt</Typography>
                    </Link>
                  </Box>
                </ListItem>
                )}
                {section.links.map((link) => (
                  <ListItem
                    disableGutters
                    key={link.title}
                    sx={{
                      pb: 0,
                      pt: 1
                    }}
                  >
                    <ListItemAvatar
                      sx={{
                        alignItems: 'center',
                        display: 'flex',
                        minWidth: 0,
                        mr: 0.5,
                      }}
                    >
                      {link.icon && (<SocialMediaIcons icon={link.icon} />)}
                      {!link.icon && (<MinusIcon color="primary" />)}
                    </ListItemAvatar>
                    <ListItemText
                      sx={{ mt: -0.5 }}
                      primary={(
                        <Link
                          href={link.href}
                          color="textPrimary"
                          variant="subtitle2"
                          target={link.target}
                        >
                          {link.title}
                        </Link>
                      )}
                    />
                  </ListItem>
                ))}
              </List>
            </Grid>
          ))}
        </Grid>
        <Divider
          sx={{
            borderColor: (theme) => alpha(theme.palette.primary.contrastText, 0.12),
            my: 6
          }}
        />
        <Typography
          color="textSecondary"
          variant="caption"
        >
          All Rights Reserved by Kumao GmbH.
        </Typography>
      </Container>
    </Box>
  );
};

export default Footer;
